import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export default function Home() {
    return (
        <Container>
            <Logout
                onClick={() => {
                    localStorage.clear();
                    window.location.href = "/";
                }}
                className="logout"
            >
                Log Out
            </Logout>
            <ul>
                <li>
                    <Link to="/join-graduate/">Join-Graduate</Link>
                </li>
                <li>
                    <Link to="/join/">Join-School</Link>
                </li>
                <li>
                    <Link to="/assessment/">Assessment/Practice</Link>
                </li>
                {/* <li>
                    <Link to="/assessment2/">Assessment 2</Link>
                </li>
                <li>
                    <Link to="/practice/">Practice</Link>
                </li> */}
                <li>
                    <Link to="/invoices/">Invoice</Link>
                </li>

                <li>
                    <Link to="/weekly-winners/">Weekly Winners</Link>
                </li>

                {/* <li>
                    <Link to="/join-higher/">Join Higher</Link>
                </li> */}
                <li>
                    <Link to="/id-card/">Id Card</Link>
                </li>
                <li>
                    <Link to="/welcome-poster/">Welcome Poster</Link>
                </li>
                <li>
                    <Link to="/ecosystem-partner/">Ecosystem Partner</Link>
                </li>
                <li>
                    <Link to="/generate-bill/">Generate Bill</Link>
                </li>
            </ul>
        </Container>
    );
}

const Container = styled.div`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
        display: flex;
        border: 1px solid #b5e2f0;
        background-color: #f5fbfd;
        border-radius: 10px;
        padding: 50px;
        flex-direction: column;
        li {
            list-style-type: none;

            a {
                display: block;
                background-color: #b5e2f0;
                margin-bottom: 15px;
                padding: 20px 50px;
                border-radius: 10px;
                text-align: center;
                text-decoration: none;
                color: #000;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }
`;
const Logout = styled.div`
    position: absolute;
    background: #fa5f55;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 38px;
    border-radius: 5px;
    color: #fff;
    top: 56px;
    right: 100px;
    cursor: pointer;
`;
