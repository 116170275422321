import { useState, useEffect } from "react";
import ".././../App.css";
import bg1 from "../../assets/images/Assessment.jpeg";
import bg2 from "../../assets/images/bg1.jpg";
import bg3 from "../../assets/images/bg2.jpg";
import bg4 from "../../assets/images/bg3.jpg";
import bg5 from "../../assets/images/bg4.jpg";
import bg6 from "../../assets/images/bg5.jpg";
import bg7 from "../../assets/images/bg6.jpg";
import bg8 from "../../assets/images/bg7.jpg";
import html2canvas from "html2canvas";
import styled from "styled-components";
import { FaStar } from "react-icons/fa";

const colors = {
    orange: "#FFBA5A",
    grey: "#a9a9a9",
};

function Congrats() {
    const [posterGenerated, setPosterGenerated] = useState(false);
    const [dp, setDp] = useState("");
    const [bgpos, setBgpos] = useState("");
    const [bgpsize, setbgSize] = useState("");
    const [dpName, setdpName] = useState("");
    const [dpClass, setdpClass] = useState("");
    const [dpDiv, setdpDiv] = useState("");
    const [space, setSpace] = useState(" ");
    const [studentClass, setStudentClass] = useState("");
    const [term, setTerm] = useState("");
    const [year, setYear] = useState("");
    const [practicename, setPracticeName] = useState("");
    const [termno, settermNo] = useState("");
    const [termordinals, setTermOrdinals] = useState("");
    const [typeTerm, setType] = useState("");
    const [mark, setMark] = useState("");
    const [img, setImg] = useState(bg1);
    const bgArray = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8];
    const [num, setNum] = useState(0);
    const [currentValue, setCurrentValue] = useState();
    const [hoverValue, setHoverValue] = useState();
    const stars = Array(10).fill(0);
    const [isAssessment, setAssessment] = useState(false);
    const [isGraduate, setGraduate] = useState(false);

    const handleClick = (value) => {
        setCurrentValue(value);
        value = mark;
    };

    const handleMouseOver = (newHoverValue) => {
        setHoverValue(newHoverValue);
    };

    const handleMouseLeave = () => {
        setHoverValue(undefined);
    };

    const download = () => {
        const download = document.getElementById("download");
        let image = document
            .querySelector("canvas")
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        download.setAttribute("href", image);
    };
    const chooseBg = () => {
        setNum((prev) => {
            if (prev === 1) {
                return 0;
            }
            return prev + 1;
        });
        setImg(bgArray[num]);
    };
    const generatePoster = () => {
        setPosterGenerated(true);
        window.scrollTo(0, 0);
        document.getElementById("render").innerHTML = "";
        html2canvas(document.querySelector("#poster"), {
            scale: 2,
        }).then((canvas) => {
            document.getElementById("render").appendChild(canvas);
        });
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    };

    return (
        <App>
            <Poster id="poster">
                <Background src={img} alt="bg" />
                <div
                    style={{
                        position: "absolute",
                        top: "473px",
                        left: "389px",
                        width: "310px",
                        height: "310px",
                        background: `url(${dp}) `,
                        backgroundSize: bgpsize,
                        backgroundPosition: bgpos,
                        borderRadius: "50%",
                    }}
                />
                {/* <Profile style={{ background: `url(${dp}) `}} /> */}

                <StudentDetails>
                    <StudentName>{dpName}</StudentName>
                    {isGraduate ? (
                        <StudentClass>
                            <Graduate>
                                {studentClass}
                                {space} {dpClass}
                                <Sup>{dpDiv} </Sup>
                                {year}
                            </Graduate>
                        </StudentClass>
                    ) : (
                        <StudentClass>
                            <ClassInfo>
                                {dpClass}
                                <Sup>{dpDiv} </Sup>
                                {studentClass}
                            </ClassInfo>
                        </StudentClass>
                    )}
                    {isAssessment ? (
                        <AssementDetails>
                            {term} {termno}
                            <Sup>{termordinals}</Sup> Term {typeTerm} Completed
                        </AssementDetails>
                    ) : (
                        <PracticeDetails>
                            <Name style={{ marginBottom: 0 }}>
                                {term} {typeTerm}
                            </Name>
                            <Name>{practicename}</Name>
                            <Name style={{ marginTop: 0 }}>Completed</Name>
                        </PracticeDetails>
                    )}

                    <AssementMark>{mark}/10</AssementMark>
                    <Star id="star">
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "row",
                            }}
                        >
                            {stars.map((_, index) => {
                                return (
                                    <FaStar
                                        key={index}
                                        size={45}
                                        onClick={() => handleClick(index + 1)}
                                        onMouseOver={() => handleMouseOver(index + 1)}
                                        onMouseLeave={handleMouseLeave}
                                        color={
                                            (hoverValue || currentValue) > index
                                                ? colors.orange
                                                : colors.grey
                                        }
                                        style={{
                                            marginRight: "0 auto",
                                            cursor: "pointer",
                                        }}
                                    />
                                );
                            })}
                        </div>
                    </Star>
                </StudentDetails>
            </Poster>
            <Bottom>
                {/* <button
                    onClick={chooseBg}
                    style={{
                        width: "100px",
                        height: "100px",
                        margin: "auto",
                        background: "#6eb8ce",
                    }}
                >
                    Change background
                </button> */}

                <DetailsHead>Student Details</DetailsHead>
                <Form>
                    <StudentInput>
                        <InputName>Student Photo</InputName>
                        <CheckboxContainer>
                            <Upload
                                type="file"
                                id="image"
                                onChange={(e) => {
                                    let file = e.target.files[0];
                                    if (file) {
                                        let reader = new FileReader();

                                        reader.onload = () => {
                                            setDp(reader.result);
                                        };

                                        reader.readAsDataURL(file);
                                    }
                                }}
                            />
                        </CheckboxContainer>
                    </StudentInput>
                    <StudentInput>
                        <InputName> Is Graduate</InputName>
                        <CheckboxContainer>
                            <CheckBox
                                type="checkbox"
                                name="name"
                                onClick={() => {
                                    setGraduate(!isGraduate);
                                }}
                            />
                        </CheckboxContainer>
                    </StudentInput>
                    <StudentInput>
                        <InputName> Is Assessment</InputName>
                        <CheckboxContainer>
                            <CheckBox
                                type="checkbox"
                                name="name"
                                onClick={() => {
                                    setAssessment(!isAssessment);
                                }}
                            />
                        </CheckboxContainer>
                    </StudentInput>

                    <StudentInput>
                        <InputName>Student name</InputName>
                        <InputField
                            type="text"
                            placeholder="Name"
                            value={dpName}
                            onChange={(e) => setdpName(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                        <InputName>Student class</InputName>
                        <InputField
                            type="text"
                            placeholder="Class/year"
                            value={dpClass}
                            onChange={(e) => setdpClass(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                        <InputName>Student Standard</InputName>
                        <InputField
                            type="text"
                            placeholder="st nd rd"
                            value={dpDiv}
                            onChange={(e) => setdpDiv(e.target.value)}
                        />
                    </StudentInput>
                    {isGraduate ? (
                        <StudentInput>
                            <InputName>Student Standard</InputName>
                            <InputField
                                type="text"
                                placeholder="Year"
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                            />
                        </StudentInput>
                    ) : (
                        ""
                    )}

                    <StudentInput>
                        <InputName>Student Course</InputName>
                        <InputField
                            type="text"
                            placeholder={isGraduate ? "course" : "standard"}
                            value={studentClass}
                            onChange={(e) => setStudentClass(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                        <InputName>Term</InputName>
                        <InputField
                            type="text"
                            placeholder="Html/CSS..."
                            value={term}
                            onChange={(e) => setTerm(e.target.value)}
                        />
                    </StudentInput>

                    {isAssessment ? (
                        <>
                            <StudentInput>
                                <InputName>Term (No)</InputName>
                                <InputField
                                    type="text"
                                    placeholder="1,2,3...."
                                    value={termno}
                                    onChange={(e) => settermNo(e.target.value)}
                                />
                            </StudentInput>
                            <StudentInput>
                                <InputName>Term (Ordinals)</InputName>
                                <InputField
                                    type="text"
                                    placeholder="st nd rd th"
                                    value={termordinals}
                                    onChange={(e) => setTermOrdinals(e.target.value)}
                                />
                            </StudentInput>
                        </>
                    ) : (
                        <>
                            <StudentInput>
                                <InputName>Practice Name</InputName>
                                <InputField
                                    type="text"
                                    placeholder="Practice Name"
                                    value={practicename}
                                    onChange={(e) => setPracticeName(e.target.value)}
                                />
                            </StudentInput>
                        </>
                    )}

                    <StudentInput>
                        <InputName>Assessment/practice</InputName>
                        <InputField
                            type="text"
                            placeholder="Assessment/practice"
                            value={typeTerm}
                            onChange={(e) => setType(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                        <InputName>Mark</InputName>
                        <InputField
                            type="text"
                            placeholder="Mark"
                            value={mark}
                            onChange={(e) => setMark(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                        <InputName>Background position</InputName>

                        <InputField
                            type="text"
                            placeholder="Position"
                            value={bgpos}
                            onChange={(e) => setBgpos(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                        <InputName>Background size</InputName>
                        <InputField
                            type="text"
                            placeholder="Size"
                            value={bgpsize}
                            onChange={(e) => setbgSize(e.target.value)}
                        />
                    </StudentInput>
                </Form>
                <Generate onClick={generatePoster}>Generate</Generate>
            </Bottom>
            <RenderPoster id="render"></RenderPoster>
            {posterGenerated ? (
                <div
                    style={{
                        width: "auto",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <a id="download" download="poster.png">
                        <Download onClick={download}>Download</Download>
                    </a>
                </div>
            ) : null}
        </App>
    );
}
const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },

    textarea: {
        border: "1px solid #a9a9a9",
        borderRadius: 5,
        padding: 10,
        margin: "20px 0",
        minHeight: 100,
        width: 300,
    },
    button: {
        border: "1px solid #a9a9a9",
        borderRadius: 5,
        width: 300,
        padding: 10,
    },
};

const App = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Poster = styled.div`
    width: 1080px;
    height: 1440px;
    position: relative;
    margin: 0 auto;
`;
const Background = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
`;

const StudentDetails = styled.div`
    width: 100%;
    background-color: rgb(255, 255, 255);
    border: 0px solid rgb(1, 2, 2);
    position: absolute;
    bottom: 260px;
    text-align: center;
    font-weight: 600;
`;

const StudentName = styled.p`
    font-size: 44px;
    color: #363941;
    margin: 0px;
    font-weight: bold;
    text-transform: uppercase;
`;
const StudentClass = styled.div`
    display: flex;
    justify-content: center;
    color: #363941;
    margin: 10px;
`;
const ClassInfo = styled.p`
    font-size: 28px;
    margin: 0 5px;
    font-family: monospace;
`;

const AssementDetails = styled.div`
    font-size: 35px;
    color: rgb(54, 57, 65);
    margin: 0px;
    font-family: monospace;
`;
const PracticeDetails = styled.div`
    font-size: 30px;

    width: 90%;
    color: rgb(54, 57, 65);
    font-family: monospace;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: -20px;
    /* margin-bottom: 20px; */
`;
const AssementMark = styled.p`
    font-size: 42px;
    margin: 0px;
    font-family: sans-serif;
    color: #cf8e1c;
`;
const Bottom = styled.div`
    display: flex;
    margin: 20px auto;
    border: 1px solid #b5e2f0;
    background-color: #f5fbfd;
    border-radius: 20px;
    padding: 30px;
    flex-direction: column;
    width: 60%;
`;

const DetailsHead = styled.span`
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
`;
const Form = styled.div`
    font-weight: 500;
    margin: 20px 0;
`;
const StudentInput = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: inherit;
`;
const Terms = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: inherit;
`;
const InputName = styled.p`
    width: 30%;
`;
const Term = styled.p``;
const Upload = styled.input`
    cursor: pointer;
    margin: 3px;
`;
const InputField = styled.input`
    padding: 15px;
    width: 64%;
    color: rgb(0, 0, 0);
    font-size: 17px;
    padding-left: 15px;
    caret-color: rgb(92, 198, 106);
    background-color: #fff;
    border: 1px solid #b5e2f0;
    border-radius: 5px;
`;
const Generate = styled.button`
    font-size: 18px;
    font-weight: 500;
    margin: 30px auto 10px;
    display: block;
    padding: 15px 60px;
    background: #9fdbed;
    border: 1px solid #fff;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background: #b5e2f0;
        border: 1px solid #b5e2f0;
        transition-duration: 0.1s;
    }
`;
const Download = styled.button`
    padding: 20px 100px;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    font-family: "product_sansbold";
    background: #d1e8e0;
    border: 1px solid #d1e8e0;
    margin: 30px 0 50px;
`;
const RenderPoster = styled.div`
    border: 1px solid red;
`;
const Star = styled.div``;
const Sup = styled.sup`
    text-transform: lowercase;
`;
const Label = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: inherit;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    width: 67%;
`;
const CheckBox = styled.input``;
const Name = styled.p`
    /* margin-right: 10px; */
    width: 100%;
    text-align: center;
    margin: 20px;
`;
const Span = styled.p`
    margin: 0 6px;
`;
const Class = styled.p`
    margin-right: 5px;
`;
const Graduate = styled.div`
    font-size: 28px;
    margin: 0 5px;
    font-family: monospace;
`;
export default Congrats;
