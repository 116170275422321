import { useEffect, useContext, useState } from "react";
import "./App.css";
import "./assets/css/style.css";
import Congrats from "./components/screens/Congrats";
import Assessment from "./components/screens/Assement";
import Assessment2 from "./components/screens/AssessmentCustom";
import Join from "./components/screens/Join";
import Login from "./components/screens/Login";
import JoinHigher from "./components/screens/JoinHigher";
import { Context } from "./Store";
import { AuthRoute } from "./components/route/AuthRoute";
import { PrivateRoute } from "./components/route/PrivateRoute";
import { BrowserRouter as Redirect, Route, Switch } from "react-router-dom";
import Home from "./components/screens/Home";
import Invoices from "./components/screens/Invoices";
import DomesticInvoice from "./components/screens/DomesticInvoice";
import GenerateBill from "./components/screens/GenerateBill";
import InterNationalInvoice from "./components/screens/InterNationalInvoice";
import WeeklyWinner from "./components/screens/WeeklyWinner";
import TechSchoolWinner from "./components/screens/weeklywinner/TechSchoolwinner";
import TechDegreeWinner from "./components/screens/weeklywinner/TechDegreeWinner";
import BestStudent from "./components/screens/weeklywinner/BestStudent";
import NextGenKeralaInvoice from "./components/screens/NextgenKeralaInvoice";
import IdCard from "./components/screens/IdCard";
import WelcomePoster from "./components/screens/WelcomePoster";
import TechDegree from "./components/screens/TechDegree";
import EcoPartner from "./components/screens/EcoPartner";
import TechXperiaInvoice from "./components/screens/TechXperiaInvoice";
import RoboticsWorkshopInvoice from "./components/screens/RoboticsWorkshopInvoice";

const AppRouter = () => {
    return (
        <section className="wrapper">
            <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/congrats/" component={Congrats} />
                <Route path="/assessment/" component={Assessment} />
                <Route path="/practice/" component={Assessment} />
                <Route path="/assessment2/" component={Assessment2} />
                <Route path="/invoices/" component={Invoices} />
                <Route path="/domestic-invoice/" component={DomesticInvoice} />
                <Route path="/generate-bill/" component={GenerateBill} />
                <Route path="/techatschool-invoice/" component={TechDegree} />

                <Route
                    path="/internationl-invoice/"
                    component={InterNationalInvoice}
                />
                <Route
                    path="/nextgenkerala-invoice/"
                    component={NextGenKeralaInvoice}
                />
                <Route
                    path="/tech-xperia-invoice/"
                    component={TechXperiaInvoice}
                />
                <Route
                    path="/robotics-workshop-invoice/"
                    component={RoboticsWorkshopInvoice}
                />
                <Route path="/join/" component={Join} />
                <Route path="/join-graduate/" component={Join} />
                <Route path="/join-higher/" component={JoinHigher} />
                <Route path="/weekly-winners/" component={WeeklyWinner} />
                <Route path="/tech-schooling/" component={TechSchoolWinner} />
                <Route path="/tech-degree/" component={TechDegreeWinner} />
                <Route path="/best-student/" component={BestStudent} />
                <Route path="/id-card/" component={IdCard} />
                <Route path="/welcome-poster/" component={WelcomePoster} />
                <Route path="/ecosystem-partner/" component={EcoPartner} />
            </Switch>
        </section>
    );
};

const AuthRouter = () => (
    <Switch>
        <Route exact path="/auth/">
            <Redirect to={{ pathname: "/auth/login/" }} />
        </Route>
        <Route path="/auth/login/" component={Login} />
    </Switch>
);

function App() {
    const [isLoading, setLoading] = useState(true);
    const { dispatch } = useContext(Context);
    async function fetchUserData() {
        let promise = new Promise((resolve, reject) => {
            let user_data = localStorage.getItem("user_data");
            if (!user_data) {
                localStorage.setItem("user_data", JSON.stringify(user_data));
                user_data = localStorage.getItem("user_data");
            }
            let user_data_stored = JSON.parse(user_data);

            dispatch({
                type: "UPDATE_USER_DATA",
                user_data: user_data_stored,
            });
            setTimeout(() => {
                resolve("done!");
                setLoading(false);
            }, 500);
        });

        let result = await promise;
    }

    useEffect(() => {
        fetchUserData();
    }, []);

    return isLoading ? (
        "Loading"
    ) : (
        <Switch>
            <AuthRoute path="/auth/" component={AuthRouter} />
            <PrivateRoute path="/" component={AppRouter} />
        </Switch>
    );
}

export default App;
