import { useState, useEffect } from "react";
import img from "../../assets/images/steyp-invoice.jpg";
import html2canvas from "html2canvas";
import styled from "styled-components";
import "../../assets/css/invoice.css";

function RoboticsWorkshopInvoice() {
  const [posterGenerated, setPosterGenerated] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [Name, setName] = useState("");
  const [sId, setsId] = useState("");
  const [sPhone, setsPhone] = useState("");
  const [invoNo, setinvoNo] = useState("");
  const [invoDate, setinvoDate] = useState("");
  const [courseFee, setcourseFee] = useState("");
  const [country, setCountry] = useState("");
  const [Total, setTotal] = useState("");
  const [amountInWords, setAmountInWords] = useState("");
  const [address, setAddress] = useState("");
  const [TransactionMode, setTransactionMode] = useState("");
  const [TransactionID, setTransactionID] = useState("");
  const [currencyType, setCurrencyType] = useState("");
  const [exchangeRate, setExchangeRate] = useState("");
  const [lut, setLut] = useState("");
  const [IeCode, setIeCode] = useState("");
  const [datetype, setDatetype] = useState(false);
  const [color, setColor] = useState(false);

  const download = () => {
    const download = document.getElementById("download");
    let image = document
      .querySelector("canvas")
      .toDataURL("image/png")
      .replace("image/pdf", "image/octet-stream");
    download.setAttribute("href", image);
  };
  const generatePoster = () => {
    setPosterGenerated(true);
    window.scrollTo(0, 0);
    document.getElementById("render").innerHTML = "";
    html2canvas(document.querySelector("#poster"), {
      scale: 2,
    }).then((canvas) => {
      document.getElementById("render").appendChild(canvas);
    });
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );
  };
  //------------------------------convert date month year---------------------------------------------

  const getDateStr = (value) => {
    let date = new Date(value);
    let month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ][date.getMonth()];
    let date_str = date.getDate() + " " + month + " " + date.getFullYear();
    return date_str;
  };
  // console.log(serviceName,"select value");

  //---------------------------------------------------------------------------
  return (
    <>
      <App className="invoice">
        <Bottom>
          <DetailsHead>Invoice Details</DetailsHead>
          <Form>
            <StudentInput>
              <InputName>Student Name</InputName>
              <InputField
                type="text"
                placeholder="Name"
                value={Name}
                onChange={(e) => setName(e.target.value)}
              />
            </StudentInput>
            <StudentInput>
              <InputName>Country</InputName>
              <InputField
                type="text"
                placeholder="country"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </StudentInput>
            <StudentInput>
              <InputName>Profile Id</InputName>
              <InputField
                type="number"
                placeholder="Profile Id"
                value={sId}
                onChange={(e) => setsId(e.target.value)}
              />
            </StudentInput>
            <StudentInput>
              <InputName>Phone</InputName>
              <InputField
                type="number"
                placeholder="+91 XXX XXXX XXX"
                value={sPhone}
                onChange={(e) => setsPhone(e.target.value)}
              />
            </StudentInput>
            <StudentInput>
              <InputName>Address</InputName>
              <InputField
                className="address"
                type="text"
                placeholder="Address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </StudentInput>

            <StudentInput>
              <InputName>Transaction Mode</InputName>
              <InputField
                type="text"
                placeholder="Transaction Mode"
                value={TransactionMode}
                onChange={(e) => setTransactionMode(e.target.value)}
              />
            </StudentInput>
            <StudentInput>
              <InputName>Currency Type</InputName>
              <InputField
                type="text"
                placeholder="Currency Type"
                value={currencyType}
                onChange={(e) => setCurrencyType(e.target.value)}
              />
            </StudentInput>
            <StudentInput>
              <InputName>LUT</InputName>
              <InputField
                type="text"
                placeholder="LUT"
                value={lut}
                onChange={(e) => setLut(e.target.value)}
              />
            </StudentInput>
            <StudentInput>
              <InputName>IE CODE</InputName>
              <InputField
                type="text"
                placeholder="IE CODE"
                value={IeCode}
                onChange={(e) => setIeCode(e.target.value)}
              />
            </StudentInput>
            <StudentInput>
              <InputName>Transaction ID</InputName>
              <InputField
                type="text"
                placeholder="Transaction ID"
                value={TransactionID}
                onChange={(e) => setTransactionID(e.target.value)}
              />
            </StudentInput>

            <StudentInput>
              <InputName>Invoice No</InputName>
              <InputField
                type="text"
                placeholder="Invoice No"
                value={invoNo}
                onChange={(e) => setinvoNo(e.target.value)}
              />
            </StudentInput>
            <StudentInput>
              <InputName>Invoice Date</InputName>
              {/* <InputField
                                onClick={() => setDatetype(true)}
                                className="date"
                                type={datetype ? "date" : "text"}
                                placeholder="Invoice Date"
                                // placeholder="dd-mm-yyyy"
                                value={invoDate}
                                onChange={(e) => setinvoDate(e.target.value)}
                            /> */}
              <InputField
                type="text"
                placeholder="Invoice Date"
                value={invoDate}
                onChange={(e) => setinvoDate(e.target.value)}
              />
            </StudentInput>

            <StudentInput>
              <InputName>Service Name</InputName>
              <Select
                onClick={() => setColor(true)}
                type={color}
                onChange={(e) => setServiceName(e.target.value)}
              >
                <option value="" disabled selected>
                  Services Name
                </option>
                <option value="Robotics Workshop">Robotics Workshop</option>
              </Select>
            </StudentInput>
            <StudentInput>
              <InputName>Course fee</InputName>
              <InputField
                type="number"
                placeholder="Course fee"
                value={courseFee}
                onChange={(e) => setcourseFee(e.target.value)}
              />
            </StudentInput>

            <StudentInput>
              <InputName>Total Amount</InputName>
              <InputField
                type="number"
                placeholder="Total"
                value={Total}
                onChange={(e) => setTotal(e.target.value)}
              />
            </StudentInput>

            <StudentInput>
              <InputName>Amount in words</InputName>
              <InputField
                type="text"
                placeholder="Amount in words"
                value={amountInWords}
                onChange={(e) => setAmountInWords(e.target.value)}
              />
            </StudentInput>
          </Form>
          <Generate onClick={generatePoster}>Generate</Generate>
        </Bottom>
        <Poster id="poster">
          <Top></Top>

          <Header>
            <LogoDiv>
              <img
                src={require("../../assets/images/steyp-logo 1.png").default}
                alt="stey-logo"
              />
            </LogoDiv>
          </Header>
          <TopSession>
            <HeaderLeft>
              <Description>
                <Title>Steyp Private Limited</Title>
                <Address>
                  208, 2nd Floor, Hilite Platino, Shankar Nagar Road, Maradu,
                  Ernakulam, Kerala, India, 682304
                </Address>
                <Email>hello@steyp.com | www.steyp.com</Email>
                <Gst>GSTIN : 32ABGCS2053P1Z5</Gst>
              </Description>
            </HeaderLeft>

            <HeaderRight>
              <Table>
                <TableTop>
                  Export Invoice
                  <p>
                    Service ment for export under bond or letter of undertaking
                    without payment of integrated tax (IGST)
                  </p>
                </TableTop>
                <TableBottom>
                  <Item>
                    <Left>INVOICE ID</Left>
                    <Middle>:</Middle>
                    <Right>{invoNo}</Right>
                  </Item>
                  <Item>
                    <Left>DATE </Left>
                    <Middle>:</Middle>
                    <Right>{invoDate}</Right>
                  </Item>
                  <Item>
                    <Left>LUT </Left>
                    <Middle>:</Middle>
                    <Right>{lut}</Right>
                  </Item>

                  <Item>
                    <Left>CURRENCY TYPE</Left>
                    <Middle>:</Middle>
                    <Right>{currencyType}</Right>
                  </Item>
                  <Item>
                    <Left> IE CODE</Left>
                    <Middle>:</Middle>
                    <Right>{IeCode}</Right>
                  </Item>
                </TableBottom>
              </Table>
            </HeaderRight>
          </TopSession>

          <MiddleSession>
            <Content>To</Content>
            <Main>
              <MiddleLeft>
                <Item>
                  <Lefts>Name</Lefts>
                  <Middle>:</Middle>
                  <Right>{Name}</Right>
                </Item>
                <Item>
                  <Lefts>Country</Lefts>
                  <Middle>:</Middle>
                  <Right>{country}</Right>
                </Item>
                <Item>
                  <Lefts>Address</Lefts>
                  <Middle>:</Middle>
                  <Rightone className="address">{address}</Rightone>
                </Item>
                <Item>
                  <Lefts>Profile ID</Lefts>
                  <Middle>:</Middle>
                  <Right>{sId}</Right>
                </Item>
                <Item>
                  <Lefts>Phone Number</Lefts>
                  <Middle>:</Middle>
                  <Right>{sPhone}</Right>
                </Item>
              </MiddleLeft>
              <MiddleRight>
                <Item>
                  <Lefts>Transaction Mode</Lefts>
                  <Middle>:</Middle>
                  <Right>{TransactionMode}</Right>
                </Item>
                <Item>
                  <Lefts>Transaction ID</Lefts>
                  <Middle>:</Middle>
                  <Right>{TransactionID}</Right>
                </Item>
              </MiddleRight>
            </Main>
          </MiddleSession>

          <BottomSession>
            <TableSession>
              <TableTwo cellSpacing={0}>
                <thead>
                  <Service>Service Name</Service>
                  <Code>SAC Code</Code>
                  <Price>Unit Price</Price>
                  <Amount>Amount</Amount>
                </thead>

                <tr>
                  <td className="tech">Course Fee - Online ({serviceName})</td>
                  <td>9992</td>
                  <td>{courseFee}</td>
                  <td>{courseFee}</td>
                </tr>
              </TableTwo>
              <MidTable>
                <LeftTable></LeftTable>
                <RightTable cellSpacing={0}>
                  <tr>
                    <Unit>Total Amount</Unit>
                    <RightPrice>{Total}</RightPrice>
                  </tr>
                </RightTable>
              </MidTable>
              <EndTable cellSpacing={0}>
                <tr>
                  <EndLeft>Amount in Words</EndLeft>
                  <EndRight>{amountInWords}</EndRight>
                </tr>
              </EndTable>
            </TableSession>

            <BottomTop>
              <BottomTitle>TERMS AND CONDITIONS :</BottomTitle>
              <Condition>
                1. Delivery of receipt will be made after the confirmation of
                the payment.
              </Condition>
              <Condition>
                2. The customer should have the responsibility to spare each
                copy of the receipt.
              </Condition>
            </BottomTop>
            <BottomEnd>
              <BottomLeft>
                <SignSession>
                  <img
                    src={require("../../assets/images/Sign.svg").default}
                    alt="Sign"
                  />
                </SignSession>
                <SealSession>
                  <img
                    src={require("../../assets/images/Steyp_Seal.svg").default}
                    alt="Steyp_Seal"
                  />
                </SealSession>
              </BottomLeft>
              <BottomRight>
                <Contents>
                  <Declaration>Declaration</Declaration>
                  <Statement>
                    We declare that this invoice shows the actual price of the
                    goods/service described and that all particulars are true
                    and correct.
                  </Statement>
                </Contents>
              </BottomRight>
            </BottomEnd>
          </BottomSession>
          <ThanksSession>Thank you for making the payment</ThanksSession>
          <End></End>
        </Poster>

        <RenderPoster id="render"></RenderPoster>
        {posterGenerated ? (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "50px",
            }}
          >
            <a id="download" download="invoice.png">
              <Download onClick={download}>Download</Download>
            </a>
          </Container>
        ) : null}
      </App>
    </>
  );
}

const App = styled.div`
  display: flex;
  flex-direction: column;
  align-items: "center";
`;
const Poster = styled.div`
  width: 946px;
  height: 1338.18px;
  position: relative;
  margin-left: 210px;
  margin-bottom: 110px;
`;
const Top = styled.div`
  width: 100%;
  background-color: #1a987c;
  height: 30px;
`;
const Button = styled.div`
  background-color: red;
  width: 200px;
  margin: 0 auto;
  z-index: 1000;
`;
const Header = styled.div`
  margin: 20px 0px;
  width: 60%;
  padding: 0px 40px;
`;
const LogoDiv = styled.div`
  width: 170px;
  img {
    display: block;
    width: 100%;
    object-fit: contain;
  }
`;
const TopSession = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 40px 20px;
  border-bottom: 1.5px solid #ececec;
`;
const HeaderLeft = styled.div`
  width: 45%;
  display: flex;
  align-items: center;
`;
const Description = styled.div``;
const Title = styled.h2`
  font-size: 25px;
  font-family: "poppinssemibold";
  margin-bottom: 13px;
`;
const Address = styled.div`
  font-size: 15px;
  color: #666666;
  font-family: "poppinsregular";
  line-height: 1.6em;
`;
const Email = styled.h4`
  font-size: 14px;
  font-family: "poppinssemibold";
  margin: 12px 0px;
`;
const Gst = styled.h4`
  font-size: 14px;
  font-family: "poppinssemibold";
`;
const HeaderRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
`;
const Table = styled.div`
  width: 100%;
  box-sizing: border-box;
  border: 1.5px solid #f4f4f4;
  background-color: #f4f4f4;
`;
const TableTop = styled.div`
  background-color: #f4f4f4;
  padding: 5px 10px;
  font-family: "poppinssemibold";
  font-size: 20px;
  width: 100%;
  line-height: 1.6em;
  p {
    font-size: 15px;
    color: #666666;
    font-family: "poppinsregular";
    line-height: 1.6em;
    margin-top: 9px;
  }
`;
const TableBottom = styled.div`
  padding: 15px 10px 1px;
  background-color: #fff;
`;
const Item = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 5px;
`;
const Left = styled.div`
  width: 60%;
  font-size: 14px;
  font-family: "poppinsregular";
  text-align: left;
  margin: auto 0;
  line-height: 1.6em;
`;
const Middle = styled.div`
  width: 10px;
  width: 5%;
  margin: 0 auto;
`;
const Right = styled.div`
  font-size: 14px;
  width: 50%;
  font-family: "poppinssemibold";
  text-align: left;
  margin: auto 0;
  line-height: 1.6em;
`;
const Rightone = styled.p`
  width: 50%;
  font-family: "poppinssemibold";
  text-align: left;
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 1.6em;
  min-height: 120px;
`;

const MiddleSession = styled.div`
  padding: 15px 40px 15px;
  border-bottom: 1.5px solid #ececec;
`;
const Content = styled.h2`
  font-size: 20px;
  margin-bottom: 10px;
  font-family: "poppinssemibold";
`;
const Main = styled.div`
  display: flex;
  justify-content: space-between;
`;
const MiddleLeft = styled.div`
  width: 45%;
`;
const Lefts = styled.div`
  width: 40%;
  font-size: 14px;
  text-align: left;
  line-height: 1.6em;
  font-family: "poppinsregular";
`;
const MiddleRight = styled.div`
  width: 45%;
`;

const BottomSession = styled.div`
  padding: 20px 40px 20px;
  border-bottom: 1px solid #000000;
`;

const TableSession = styled.div``;
const TableTwo = styled.table`
  width: 100%;

  thead {
    width: 100%;
    background-color: #545353;
    color: #fff;
    text-align: left;
  }
  tr {
    width: 100%;

    td {
      border: 1px solid #ececec;
      padding: 16px;
      font-size: 14px;
      font-family: "poppinssemibold";
      text-align: right;
      &.tech {
        text-align: left;
        line-height: 1.6em;
      }
    }
  }
`;

const Service = styled.th`
  width: 35%;
  padding: 15px;
  font-size: 14px;
  font-family: "poppinssemibold";
  border: 1px solid #ececec;
`;
const Code = styled.th`
  width: 15%;
  padding: 15px;
  font-size: 14px;
  font-family: "poppinssemibold";
  border: 1px solid #ececec;
`;
const Price = styled.th`
  width: 30%;
  padding: 15px;
  font-size: 14px;
  font-family: "poppinssemibold";
  border: 1px solid #ececec;
`;
const Amount = styled.th`
  width: 20%;
  padding: 15px;
  font-size: 14px;
  font-family: "poppinssemibold";
  border: 1px solid #ececec;
`;
const MidTable = styled.table`
  width: 100%;
  display: flex;
`;
const LeftTable = styled.table`
  width: 50%;
`;
const RightTable = styled.table`
  width: 50%;
  text-align: right;
  tr {
    width: 100%;
  }
`;
const Unit = styled.td`
  width: 30%;
  padding: 15px;
  font-size: 14px;
  background-color: #ececec;
  font-family: "poppinssemibold";
  border: 1px solid #fff;
  text-align: left;
`;
const RightPrice = styled.td`
  width: 20%;
  padding: 15px;
  font-size: 14px;
  background-color: #f4f4f4;
  font-family: "poppinssemibold";
  border: 1px solid #fff;
  text-align: right;
`;
const EndTable = styled.table`
  width: 100%;
  tr {
    width: 100%;
  }
`;
const EndLeft = styled.td`
  width: 35%;
  padding: 15px;
  font-size: 14px;

  font-family: "poppinssemibold";
  border: 1px solid #ececec;
  text-align: left;
`;
const EndRight = styled.td`
  width: 65%;
  padding: 15px;
  font-size: 14px;
  font-family: "poppinssemibold";
  border: 1px solid #ececec;
  text-align: left;
`;

const BottomTop = styled.div``;
const BottomTitle = styled.h3`
  margin: 20px 0px;
  font-size: 17px;
  font-family: "poppinssemibold";
`;
const Condition = styled.div`
  font-size: 14px;
  font-family: "poppinsregular";
  margin-bottom: 10px;
  color: #666666;
  &:last-child {
    margin-bottom: 0;
  }
`;
const BottomEnd = styled.div`
  margin: 20px 0px 0px;
  display: flex;
`;
const BottomLeft = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SignSession = styled.div`
  width: 30%;
  img {
    display: block;
    width: 100%;
  }
`;
const SealSession = styled.div`
  width: 30%;
  margin-left: 20px;
  img {
    display: block;
    width: 100%;
  }
`;
const BottomRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
`;
const Contents = styled.div`
  width: 63%;
  margin-top: 40px;
`;
const Declaration = styled.div`
  font-size: 16px;
  font-family: "poppinssemibold";
  margin-bottom: 10px;
`;
const Statement = styled.div`
  font-size: 14px;
  font-family: "poppinsregular";
  color: #666666;
  line-height: 1.6em;
`;
const ThanksSession = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
  color: #1a987c;
  font-family: "poppinsregular";
  padding: 15px 0px;
`;
const End = styled.div`
  width: 100%;
  background-color: #18484c;
  height: 10px;
`;

const Bottom = styled.div`
  margin-top: 500px;
  display: flex;
  justify-content: center;
  margin: 10px auto 30px;
  flex-direction: column;
  width: 60%;
  border: 1px solid #b5e2f0;
  background-color: #f5fbfd;
  border-radius: 20px;
  padding: 40px 30px;
`;

const DetailsHead = styled.span`
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 30px;
`;
const Form = styled.div`
  text-transform: capitalize;
  font-weight: 500;
`;
const StudentInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
`;
const InputName = styled.p``;
const Select = styled.select`
  padding: 15px;
  width: 70%;
  color: ${({ type }) => (type ? "#000" : "#7a777d")};
  font-size: 17px;
  background-color: #fff;
  border: 1px solid #b5e2f0;
  border-radius: 5px;
  -webkit-appearance: none;
  margin: 0;
  option {
    color: #000 !important;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const InputField = styled.input`
  padding: 15px;
  width: 70%;
  color: #000 !important;
  font-size: 17px;
  background-color: #fff;
  border: 1px solid #b5e2f0;
  border-radius: 5px;
  -webkit-appearance: none;
  margin: 0;
  ::placeholder {
    color: #7a777d !important;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const Generate = styled.span`
  font-size: 18px;
  font-weight: 500;
  margin: 30px auto 10px;
  display: block;
  padding: 15px 60px;
  background: #9fdbed;
  border: 1px solid #fff;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: #b5e2f0;
    border: 1px solid #b5e2f0;
    transition-duration: 0.1s;
  }
`;
const Download = styled.span`
  padding: 20px 100px;
  border-radius: 10px;
  cursor: pointer;
  font-family: "product_sansbold";
  background: #d1e8e0;
  color: #000;
`;
const RenderPoster = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  canvas {
    border: 1px solid #e8e8e8;
  }
`;
const Container = styled.div`
  margin-top: 10px;
`;

export default RoboticsWorkshopInvoice;
