import { useState, useEffect } from "react";
import "../../App.css";
import bg1 from "../../assets/images/Assessment.jpeg";
import bg2 from "../../assets/images/bg1.jpg";
import bg3 from "../../assets/images/bg2.jpg";
import bg4 from "../../assets/images/bg3.jpg";
import bg5 from "../../assets/images/bg4.jpg";
import bg6 from "../../assets/images/bg5.jpg";
import bg7 from "../../assets/images/bg6.jpg";
import bg8 from "../../assets/images/bg7.jpg";
import html2canvas from "html2canvas";
import styled from "styled-components";
import { FaStar } from "react-icons/fa";

const colors = {
  orange: "#FFBA5A",
  grey: "#a9a9a9",
};

function Congrats() {
  const [posterGenerated, setPosterGenerated] = useState(false);
  const [dp, setDp] = useState("");
  const [bgpos, setBgpos] = useState("");
  const [bgpsize, setbgSize] = useState("");
  const [dpName, setdpName] = useState("");
  const [dpClass, setdpClass] = useState("");
  const [dpDiv, setdpDiv] = useState("");
  const [term, setTerm] = useState("");
  const [termno, settermNo] = useState("");
  const [typeTerm, setType] = useState("");
  const [mark, setMark] = useState("");
  const [img, setImg] = useState(bg1);
  const bgArray = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8];
  const [num, setNum] = useState(0);
  const [currentValue, setCurrentValue] = useState();
  const [hoverValue, setHoverValue] = useState();
  const stars = Array(10).fill(0);

  const handleClick = (value) => {
    setCurrentValue(value);
    value = mark;
  };

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue);
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined);
  };

  const download = () => {
    const download = document.getElementById("download");
    let image = document
      .querySelector("canvas")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    download.setAttribute("href", image);
  };
  const chooseBg = () => {
    setNum((prev) => {
      if (prev === 7) {
        return 0;
      }
      return prev + 1;
    });
    setImg(bgArray[num]);
  };
  const generatePoster = () => {
    setPosterGenerated(true);
    window.scrollTo(0, 0);
    document.getElementById("render").innerHTML = "";
    html2canvas(document.querySelector("#poster"), {
      scale: 2,
    }).then((canvas) => {
      document.getElementById("render").appendChild(canvas);
    });
    window.scrollTo(
      0,
      document.body.scrollHeight || document.documentElement.scrollHeight
    );
  };

  return (
    <App>
      <Poster id="poster">
        <Background src={img} alt="bg" />
        <div
          style={{
            position: "absolute",
            top: "452px",
            left: "389px",
            width: "310px",
            height: "310px",
            background: `url(${dp}) `,
            backgroundSize: bgpsize,
            backgroundPosition: bgpos,
            borderRadius: "50%",
          }}
        />
        {/* <Profile style={{ background: `url(${dp}) `}} /> */}
        <StudentDetails>
          <StudentName>{dpName}</StudentName>
          <StudentClass>
            <ClassInfo>{dpClass} </ClassInfo>
            <ClassInfo>{dpDiv} </ClassInfo>
          </StudentClass>
          <AssementDetails>{term}</AssementDetails>
          <AssementMark>{mark}/10</AssementMark>
          <Star id="star">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              {stars.map((_, index) => {
                return (
                  <FaStar
                    key={index}
                    size={60}
                    onClick={() => handleClick(index + 1)}
                    onMouseOver={() => handleMouseOver(index + 1)}
                    onMouseLeave={handleMouseLeave}
                    color={
                      (hoverValue || currentValue) > index
                        ? colors.orange
                        : colors.grey
                    }
                    style={{
                      marginRight: "0 auto",
                      cursor: "pointer",
                    }}
                  />
                );
              })}
            </div>
          </Star>
        </StudentDetails>
      </Poster>
      <Bottom>
        {/* <button
                    onClick={chooseBg}
                    style={{
                        width: "100px",
                        height: "100px",
                        margin: "auto",
                        background: "#6eb8ce",
                    }}
                >
                    Change background
                </button> */}
        <Upload
          type="file"
          id="image"
          onChange={(e) => {
            let file = e.target.files[0];
            if (file) {
              let reader = new FileReader();

              reader.onload = () => {
                setDp(reader.result);
              };

              reader.readAsDataURL(file);
            }
          }}
        />
        <Form>
          <StudentInput>
            <InputName>student name</InputName>
            <InputField
              type="text"
              placeholder="name"
              value={dpName}
              onChange={(e) => setdpName(e.target.value)}
            />
          </StudentInput>
          <StudentInput>
            <InputName>student class</InputName>
            <InputField
              type="text"
              placeholder="Class/year"
              value={dpClass}
              onChange={(e) => setdpClass(e.target.value)}
            />
          </StudentInput>
          <StudentInput>
            <InputName>student Divison</InputName>
            <InputField
              type="text"
              placeholder="Division"
              value={dpDiv}
              onChange={(e) => setdpDiv(e.target.value)}
            />
            {/* </StudentInput>
          <StudentInput>
            <InputName>Term</InputName>
            <InputField
              type="text"
              placeholder="term"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </StudentInput>
          <StudentInput>
            <InputName>Term No</InputName>
            <InputField
              type="text"
              placeholder="st nd rd"
              value={termno}
              onChange={(e) => settermNo(e.target.value)}
            /> */}
          </StudentInput>
          <StudentInput>
            <InputName>Assessment/practice Details</InputName>
            <InputField
              type="text"
              placeholder="Assessment/practice"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
          </StudentInput>
          <StudentInput>
            <InputName>Mark</InputName>
            <InputField
              type="text"
              placeholder="Mark"
              value={mark}
              onChange={(e) => setMark(e.target.value)}
            />
          </StudentInput>
          <StudentInput>
            <InputName>background position</InputName>

            <InputField
              type="text"
              placeholder="position"
              value={bgpos}
              onChange={(e) => setBgpos(e.target.value)}
            />
          </StudentInput>
          <StudentInput>
            <InputName>background size</InputName>
            <InputField
              type="text"
              placeholder="size"
              value={bgpsize}
              onChange={(e) => setbgSize(e.target.value)}
            />
          </StudentInput>
        </Form>
        <Generate onClick={generatePoster}>Generate</Generate>
      </Bottom>
      <RenderPoster
        id="render"
        style={{
          width: "1080px",
          height: "1440px",
        }}
      ></RenderPoster>
      {posterGenerated ? (
        <div
          style={{
            width: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <a id="download" download="poster.png">
            <Download onClick={download}>Download</Download>
          </a>
        </div>
      ) : null}
    </App>
  );
}
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: 300,
  },
  button: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    width: 300,
    padding: 10,
  },
};

const App = styled.div``;
const Poster = styled.div`
  width: 1080px;
  height: 1440px;
  position: relative;
  margin: 0 auto;
`;
const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
`;

const StudentDetails = styled.div`
  width: inherit;
  background-color: rgb(255, 255, 255);
  border: 0px solid rgb(1, 2, 2);
  position: absolute;
  bottom: 316px;
  text-align: center;
  margin: 10px;
  font-weight: 600;
`;

const StudentName = styled.p`
  font-size: 50px;
  color: #363941;
  margin: 0px;
  font-weight: bold;
  text-transform: uppercase;
`;
const StudentClass = styled.div`
  display: flex;
  justify-content: center;
  text-transform: capitalize;
  color: #363941;
  margin: 10px;
`;
const ClassInfo = styled.p`
  font-size: 28px;
  margin: 0 5px;
  font-family: monospace;
`;
const AssementDetails = styled.p`
  font-size: 35px;
  color: rgb(54, 57, 65);
  margin: 0px;
  text-transform: capitalize;
  font-family: monospace;
`;
const AssementMark = styled.p`
  font-size: 72px;
  margin: 0px;
  text-transform: capitalize;
  font-family: sans-serif;
  color: #cf8e1c;
`;
const Bottom = styled.div`
  display: flex;
  margin: 0px auto;
  flex-direction: column;
  width: 60%;
`;
const Form = styled.div`
  text-transform: capitalize;
  font-weight: 500;
  margin: 20px 0;
`;
const StudentInput = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: inherit;
`;
const InputName = styled.p``;
const Upload = styled.input`
  margin: 0px auto;
  padding-top: 21px;
`;
const InputField = styled.input`
  margin-left: 30px;
  width: 60%;
  color: rgb(0, 0, 0);
  font-size: 17px;
  padding-left: 15px;
  caret-color: rgb(92, 198, 106);
  border: 1px solid #d8c4c4;
  border-radius: 4px;
`;
const Generate = styled.button`
  width: 100px;
  height: 100px;
  margin: 0px auto;
  display: block;
  background: #d1e8e0;
`;
const Download = styled.button`
  font-size: x-large;
  padding: revert;
  margin: 15px;
`;
const RenderPoster = styled.div``;
const Star = styled.div``;
const Sup = styled.sup`
  text-transform: lowercase;
`;
export default Congrats;
