import { useState, useEffect } from "react";
import "../../App.css";
import bg from "../../assets/images/congrats.jpg";
import html2canvas from "html2canvas";
import styled from "styled-components";

function App() {
    const [posterGenerated, setPosterGenerated] = useState(false);
    const [dp, setDp] = useState("");
    const [bgpos, setBgpos] = useState("center");
    const [bgpsize, setbgSize] = useState("cover");
    const [dpName, setdpName] = useState("");
    const [dpCourse, setdpCourse] = useState("");
    const [dpCampus, setdpCampus] = useState("");

    const download = () => {
        const download = document.getElementById("download");
        let image = document
            .querySelector("canvas")
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        download.setAttribute("href", image);
    };
    const generatePoster = () => {
        setPosterGenerated(true);
        window.scrollTo(0, 0);
        document.getElementById("render").innerHTML = "";
        html2canvas(document.querySelector("#poster"), {
            scale: 2,
        }).then((canvas) => {
            document.getElementById("render").appendChild(canvas);
        });
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    };
    return (
        <div className="App">
            <div
                id="poster"
                style={{
                    width: "1080px",
                    height: "1080px",
                    position: "relative",
                }}
            >
                <div id="tawk_6090e358b1d5182476b55615"></div>

                <img
                    src={bg}
                    alt="bg"
                    style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                    }}
                />
                <div
                    style={{
                        position: "absolute",
                        top: "450px",
                        left: "145px",
                        width: "286px",
                        height: "374px",
                        background: `url(${dp}) `,
                        backgroundSize: bgpsize,
                        backgroundPosition: bgpos,
                    }}
                />
                <div
                    style={{
                        minWidth: "312px",
                        height: "110px",
                        backgroundColor: "#dbdbdb",
                        border: "0px solid #010202",
                        position: "absolute",
                        top: "677px",
                        left: "480px",
                        padding: "10px",
                    }}
                >
                    <div
                        className="profile"
                        style={{
                            margin: "10px",
                            fontWeight: "600",
                            textTransform: "uppercase",
                        }}
                    >
                        <p
                            className="student-name"
                            style={{
                                fontSize: "30px",
                                color: "#19484c",
                                margin: 0,
                            }}
                        >
                            {dpName}
                        </p>
                        <p
                            className="student-course"
                            style={{
                                fontSize: "25px",
                                color: "#507239",
                                margin: 0,
                            }}
                        >
                            {dpCourse}{" "}
                        </p>
                        <p
                            className="student-campus"
                            style={{
                                fontSize: "24px",
                                color: "#507239",
                                margin: 0,
                            }}
                        >
                            {dpCampus}
                        </p>
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    margin: "20px auto",
                    border: "1px solid #b5e2f0",
                    backgroundColor: "#f5fbfd",
                    borderRadius: "20px",
                    padding: "30px",
                    flexDirection: "column",
                    width: "60%",
                }}
            >
                <span
                    style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "20px",
                        fontWeight: "500",
                        marginBottom: "30px",
                    }}
                >
                    Student Details
                </span>
                <div className="form" style={{ textTransform: "capitalize", fontWeight: "500" }}>
                    <div
                        className="studnt_details"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "15px",
                        }}
                    >
                        <p>Student Photo</p>
                        <input
                            style={{
                                width: "67%",
                            }}
                            type="file"
                            id="image"
                            onChange={(e) => {
                                let file = e.target.files[0];
                                if (file) {
                                    let reader = new FileReader();

                                    reader.onload = () => {
                                        setDp(reader.result);
                                    };

                                    reader.readAsDataURL(file);
                                }
                            }}
                        />
                    </div>
                    <div
                        className="studnt_details"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "15px",
                        }}
                    >
                        <p>student name</p>
                        <input
                            style={{
                                padding: "15px",
                                width: "64%",
                                color: "rgb(0, 0, 0)",
                                fontSize: "17px",
                                caretColor: "rgb(92, 198, 106)",
                                backgroundColor: "#fff",
                                border: "1px solid #b5e2f0",
                                borderRadius: "5px",
                            }}
                            type="text"
                            placeholder="Name"
                            value={dpName}
                            onChange={(e) => setdpName(e.target.value)}
                        />
                    </div>
                    <div
                        className="studnt_details"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "15px",
                        }}
                    >
                        <p>student course</p>
                        <input
                            style={{
                                padding: "15px",
                                width: "64%",
                                color: "rgb(0, 0, 0)",
                                fontSize: "17px",
                                caretColor: "rgb(92, 198, 106)",
                                backgroundColor: "#fff",
                                border: "1px solid #b5e2f0",
                                borderRadius: "5px",
                            }}
                            type="text"
                            placeholder="Course"
                            value={dpCourse}
                            onChange={(e) => setdpCourse(e.target.value)}
                        />
                    </div>
                    <div
                        className="studnt_details"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "15px",
                        }}
                    >
                        <p>student campus</p>
                        <input
                            style={{
                                padding: "15px",
                                width: "64%",
                                color: "rgb(0, 0, 0)",
                                fontSize: "17px",
                                caretColor: "rgb(92, 198, 106)",
                                backgroundColor: "#fff",
                                border: "1px solid #b5e2f0",
                                borderRadius: "5px",
                            }}
                            type="text"
                            placeholder="Campus"
                            value={dpCampus}
                            onChange={(e) => setdpCampus(e.target.value)}
                        />
                    </div>
                    <div
                        className="studnt_details"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "15px",
                        }}
                    >
                        <p style={{ marginLeft: "10px," }}>background position</p>
                        <input
                            style={{
                                padding: "15px",
                                width: "64%",
                                color: "rgb(0, 0, 0)",
                                fontSize: "17px",
                                caretColor: "rgb(92, 198, 106)",
                                backgroundColor: "#fff",
                                border: "1px solid #b5e2f0",
                                borderRadius: "5px",
                            }}
                            type="text"
                            placeholder="Position"
                            value={bgpos}
                            onChange={(e) => setBgpos(e.target.value)}
                        />
                    </div>
                    <div
                        className="studnt_details"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "15px",
                        }}
                    >
                        <p>background size</p>
                        <input
                            style={{
                                padding: "15px",
                                width: "64%",
                                color: "rgb(0, 0, 0)",
                                fontSize: "17px",
                                caretColor: "rgb(92, 198, 106)",
                                backgroundColor: "#fff",
                                border: "1px solid #b5e2f0",
                                borderRadius: "5px",
                            }}
                            type="text"
                            placeholder="Size"
                            value={bgpsize}
                            onChange={(e) => setbgSize(e.target.value)}
                        />
                    </div>
                </div>
                <button
                    onClick={generatePoster}
                    style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        margin: "30px auto 10px",
                        display: "block",
                        padding: "15px 60px",
                        background: "#9fdbed",
                        border: "1px solid #fff",
                        borderRadius: "10px",
                        cursor: "pointer",
                    }}
                >
                    Generate Poster
                </button>
            </div>
            <Render
                id="render"
                style={{
                    width: "1080px",
                    height: "1080px",
                }}
            ></Render>
            {posterGenerated ? (
                <a id="download" download="poster.png">
                    <button
                        style={{
                            padding: "20px 100px",
                            borderRadius: "10px",
                            fontSize: "18px",
                            cursor: "pointer",
                            fontFamily: "product_sansbold",
                            background: "#d1e8e0",
                            border: "1px solid #d1e8e0",
                            margin: "30px 0 50px",
                        }}
                        type="button"
                        onClick={download}
                    >
                        Download
                    </button>
                </a>
            ) : null}
        </div>
    );
}

const Render = styled.div`
    canvas {
        border: 2px solid #e8e8e8;
    }
`;

export default App;
