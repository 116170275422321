import { useState, useEffect, useRef } from "react";
import img from "../../../assets/images/steyp-invoice.jpg";
import html2canvas from "html2canvas";
import styled from "styled-components";
import "../../../assets/css/invoice.css";

function BestStudent() {
    const [posterGenerated, setPosterGenerated] = useState(false);

    const [Name, setName] = useState("");
    const[colegename,setColageName] =useState("")
    const [sId, setsId] = useState("");
    const [collgesId, setCollegeId] = useState("");
    const [points, setPoints] = useState("");
    const [collagepoints, setCollagePoints] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [college, setCollege] = useState(null);
    const [startdate, setStartDate] = useState("");
    const [enddate, setEndDate] = useState("");
    const [startMonth, setStartMonth] = useState("");
    const [endMonth, setEndMonth] = useState("");
    const [startyear, setStartYear] = useState("");
    const [endyear, setEndYear] = useState("");
    const imageRef = useRef(null);

    const download = () => {
        const download = document.getElementById("download");
        let image = document
            .querySelector("canvas")
            .toDataURL("image/png")
            .replace("image/pdf", "image/octet-stream");
        download.setAttribute("href", image);
    };
    const generatePoster = () => {
        setPosterGenerated(true);
        window.scrollTo(0, 0);
        document.getElementById("render").innerHTML = "";
        html2canvas(document.querySelector("#poster"), {
            scale: 1,
        }).then((canvas) => {
            document.getElementById("render").appendChild(canvas);
        });
        window.scrollTo(
            0,
            document.body.scrollHeight || document.documentElement.scrollHeight
        );
    };

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setCollege(file);
    };

    // console.log(serviceName,"select value");

    //---------------------------------------------------------------------------
    return (
        <>
            <App className="invoice">
                <Bottom>
                    <DetailsHead>Weekly Winners</DetailsHead>
                    <FormContienr>

                   
                    <Form>
                        <h4>School Student</h4>
                        <StudentInput>
                            <InputName>Student Name</InputName>
                            <InputField
                                type="text"
                                placeholder="Name"
                                value={Name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </StudentInput>

                        <StudentInput>
                            <InputName>Student ID</InputName>
                            <InputField
                                type="number"
                                placeholder="Student ID"
                                value={sId}
                                onChange={(e) => setsId(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>Points</InputName>
                            <InputField
                                // className="address"
                                type="number"
                                placeholder="Points"
                                value={points}
                                onChange={(e) => setPoints(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>School Topper image</InputName>
                            <InputField
                                // className="address"
                                type="file"
                                onChange={handleFileInputChange}
                            />
                        </StudentInput>
                        </Form>
                        <Form>
                        <h4>College Student</h4>
                        <StudentInput>
                            <InputName>Student Name</InputName>
                            <InputField
                                type="text"
                                placeholder="Name"
                                value={colegename}
                                onChange={(e) => setColageName(e.target.value)}
                            />
                        </StudentInput>

                        <StudentInput>
                            <InputName>Student ID</InputName>
                            <InputField
                                type="number"
                                placeholder="Student ID"
                                value={collgesId}
                                onChange={(e) => setCollegeId(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>Points</InputName>
                            <InputField
                                // className="address"
                                type="number"
                                placeholder="Points"
                                value={collagepoints}
                                onChange={(e) => setCollagePoints(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>College Topper image</InputName>
                            <InputField
                                // className="address"
                                type="file"
                                onChange={handleImageChange}
                            />
                        </StudentInput>
                        </Form>
                        <Form>
                        <h4>Date details</h4>
                        <StudentInput>
                            <InputName>Start Date</InputName>
                            <InputField
                                type="number"
                                placeholder="Start date"
                                value={startdate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>End Date</InputName>
                            <InputField
                                type="number"
                                placeholder="End date"
                                value={enddate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>Start Month</InputName>
                            <InputField
                                type="text"
                                placeholder="Start Month"
                                value={startMonth}
                                onChange={(e) => setStartMonth(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>End Month</InputName>
                            <InputField
                                type="text"
                                placeholder="End Month"
                                value={endMonth}
                                onChange={(e) => setEndMonth(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>Start Year </InputName>
                            <InputField
                                type="text"
                                placeholder="End Year"
                                value={startyear}
                                onChange={(e) => setStartYear(e.target.value)}
                            />
                        </StudentInput>
                        <StudentInput>
                            <InputName>End Year</InputName>
                            <InputField
                                type="text"
                                placeholder="End Year"
                                value={endyear}
                                onChange={(e) => setEndYear(e.target.value)}
                            />
                        </StudentInput>
                        </Form>
                        </FormContienr>
                   
                    <Generate onClick={generatePoster}>Generate</Generate>
                </Bottom>
                <Poster id="poster">
                    <Header>
                        <LogoDiv>
                            <img
                                src={
                                    require("../../../assets/images/weekly-winner/best-student.png")
                                        .default
                                }
                                alt="image"
                            />
                        </LogoDiv>
                        <DayContiner>
                            <span>
                            {startMonth}{" "}{startdate ? startdate : "00"},{" "}{startyear} - {" "}
                            {endMonth}{" "}{enddate ? enddate : "00"},{" "}{endyear}{" "}
                            </span>
                        </DayContiner>
                        <NameDiv>
                            <h4>{Name}</h4>
                        </NameDiv>
                        <StudentIdContiner>
                        <span>ID:{collgesId?collgesId:"0"} | Points:{collagepoints?collagepoints:"0"}</span>
                        </StudentIdContiner>
                        <CollegeNameDiv>
                            <h4>{colegename}</h4>
                        </CollegeNameDiv>
                        <CollegeStudentIdContiner>
                        <span>ID:{sId?sId:"0"} | Points:{points?points:"0"} </span>
                            
                        </CollegeStudentIdContiner>

                        <StudentImageContier>
                            <ImageContienr>
                                {college&& (
                                    <img
                                        ref={imageRef}
                                        src={URL.createObjectURL(college)}
                                        alt="Selected image"
                                    />
                                )}
                            </ImageContienr>
                        </StudentImageContier>
                        <CollegeImageContiner>
                            <ImageContienr>
                                {selectedFile && (
                                    <img
                                        ref={imageRef}
                                        src={URL.createObjectURL(selectedFile)}
                                        alt="Selected image"
                                    />
                                )}
                            </ImageContienr>
                        </CollegeImageContiner>
                    </Header>
                </Poster>

                <RenderPoster id="render"></RenderPoster>
                {posterGenerated ? (
                    <Container
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "50px",
                        }}
                    >
                        <a id="download" download="weeklywinner.png">
                            <Download onClick={download}>Download</Download>
                        </a>
                    </Container>
                ) : null}
            </App>
        </>
    );
}

const App = styled.div`
    display: flex;
    flex-direction: column;
    align-items: "center";
`;
const Poster = styled.div`
   width: 48%;
    margin: 0 auto;
    margin-bottom: 110px;
`;

const Header = styled.div`
     position: relative;
    width: 100%;
    max-width: 770px;
    min-width: 770px;
    /* padding: 0px 40px; */
`;
const LogoDiv = styled.div`
    width: 100%;
    img {
        display: block;
        width: 100%;
        object-fit: contain;
    }
`;
const Bottom = styled.div`
    margin-top: 500px;
    display: flex;
    justify-content: center;
    margin: 10px auto 30px;
    flex-direction: column;
    width: 90%;
    border: 1px solid #b5e2f0;
    background-color: #f5fbfd;
    border-radius: 20px;
    padding: 40px 30px;
`;

const DetailsHead = styled.span`
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
`;
const Form = styled.div`
    text-transform: capitalize;
    font-weight: 500;
    width:48%;
    margin-bottom: 20px;
    h4{
        text-align: center;
        font-size: 20px;
        font-weight: 500;
    }
    &:last-child{
        align-items: center;
    }
`;
const FormContienr = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

const StudentInput = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 13px;
`;
const InputName = styled.p``;
const InputField = styled.input`
    padding: 15px;
    width: 70%;
    color: #000 !important;
    font-size: 17px;
    background-color: #fff;
    border: 1px solid #b5e2f0;
    border-radius: 5px;
    -webkit-appearance: none;
    margin: 0;
    ::placeholder {
        color: #7a777d !important;
    }
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
const Generate = styled.span`
    font-size: 18px;
    font-weight: 500;
    margin: 30px auto 10px;
    display: block;
    padding: 15px 60px;
    background: #9fdbed;
    border: 1px solid #fff;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background: #b5e2f0;
        border: 1px solid #b5e2f0;
        transition-duration: 0.1s;
    }
`;
const Download = styled.span`
    padding: 20px 100px;
    border-radius: 10px;
    cursor: pointer;
    font-family: "product_sansbold";
    background: #d1e8e0;
    color: #000;
`;
const RenderPoster = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 65px;
    margin-top: 40px;
`;
const Container = styled.div`
    margin-top: 10px;
`;
const DayContiner = styled.div`
    top: 34%;
    position: absolute;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    span {
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #439a7e;
    }
`;
const NameDiv = styled.div`
          top: 79%;
    position: absolute;
    width: 60%;
    left: 7%;
    margin: 0 auto;
    text-align: center;
    h4 {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    color: #439a7e;
    width: 76%;

   
    }
`;
const StudentIdContiner = styled.div`
 top: 82%;
    position: absolute;
    width: 70%;
    left: 33%;
    margin: 0 auto;
    text-align: center;
  
    span {
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        color: #1a1a1b;
    }
`;
const CollegeNameDiv = styled.div`
   top: 79%;
    position: absolute;
    margin: 0 auto;
    text-align: center;
    left: 48%;
    width: 52%;
    h4 {
        font-size: 20px;
    text-align: center;
    font-weight: 600;
    color: #439a7e;
    width: 76%;
    }
`;
const CollegeStudentIdContiner = styled.div`
       position: absolute;
    margin: 0 auto;
    text-align: center;
    top: 82%;
    left: 4%;
    width: 50%;
    span {
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        color: #1a1a1b;
    }
`;
const StudentImageContier = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(24%,17%);
`;
const CollegeImageContiner = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-131%,16.5%);
`;
const ImageContienr = styled.div`
     width: 191px;
    height: 205px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        display: block;
    }
`;
export default BestStudent;
