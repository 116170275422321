import { useState } from "react";
import "../../App.css";
import IdImage from "../../assets/images/Eco-Poster.jpg";
import html2canvas from "html2canvas";
import styled from "styled-components";
import "../../style.css";

function EcoPartner() {
    const [posterGenerated, setPosterGenerated] = useState(false);
    const [Name, setName] = useState("");
    const [Id, setId] = useState("");
    const [dp, setDp] = useState("");
    const [bgpos, setBgpos] = useState("center");
    const [bgpsize, setbgSize] = useState("cover");
    const [startdate, setStartDate] = useState("");

    const download = () => {
        const download = document.getElementById("download");
        let image = document
            .querySelector("canvas")
            .toDataURL("image/png")
            .replace("image/pdf", "image/octet-stream");
        download.setAttribute("href", image);
    };
    const generatePoster = () => {
        setPosterGenerated(true);
        window.scrollTo(0, 0);
        document.getElementById("render").innerHTML = "";
        html2canvas(document.querySelector("#poster"), {
            scale: 2,
        }).then((canvas) => {
            document.getElementById("render").appendChild(canvas);
        });
        window.scrollTo(
            0,
            document.body.scrollHeight || document.documentElement.scrollHeight
        );
    };

    return (
        <App>
            <Bottom>
                <DetailsHead>Student Details</DetailsHead>
                <Form>
                    <StudentInput>
                        <InputName>College Name</InputName>
                        <InputField
                            type="text"
                            placeholder="Name"
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                        <InputName>College Logo</InputName>
                        <PhotoContainer>
                            <Upload
                                type="file"
                                id="image"
                                onChange={(e) => {
                                    let file = e.target.files[0];
                                    if (file) {
                                        let reader = new FileReader();

                                        reader.onload = () => {
                                            setDp(reader.result);
                                        };

                                        reader.readAsDataURL(file);
                                    }
                                }}
                            />
                        </PhotoContainer>
                    </StudentInput>
                    <StudentInput>
                        <InputName>ID</InputName>
                        <InputField
                            type="text"
                            placeholder="ID"
                            value={Id}
                            onChange={(e) => setId(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                            <InputName>Date</InputName>
                             <InputField
                                 type="text"
                                placeholder="Date"
                                 value={startdate}
                                 onChange={(e) => setStartDate(e.target.value)}
                            />
                        </StudentInput>
                    <StudentInput>
                        <InputName>background position</InputName>

                        <InputField
                            type="text"
                            placeholder="position"
                            value={bgpos}
                            onChange={(e) => setBgpos(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                        <InputName>background size</InputName>
                        <InputField
                            type="text"
                            placeholder="size"
                            value={bgpsize}
                            onChange={(e) => setbgSize(e.target.value)}
                        />
                    </StudentInput>
                </Form>
                <Generate onClick={generatePoster}>Generate</Generate>
            </Bottom>
            <Poster id="poster">
                <Background src={IdImage} alt="bg" />
                <ImageDiv>{dp.length > 0 ? <Image src={dp} /> : null}</ImageDiv>
                <StudentDetails>
                    <StudentName>{Name}</StudentName>
                </StudentDetails>
                <StudentId>
                    <StudentClass>{Id}</StudentClass>
                </StudentId>
                <StudentDate>
                    <StudentSchool>{startdate}</StudentSchool>
                </StudentDate>
            </Poster>
            <RenderPoster
                id="render"
                style={{
                    width: "640px",
                    height: "830px",
                    marginTop: "100px",
                }}
            ></RenderPoster>
            {posterGenerated ? (
                <div
                    style={{
                        width: "auto",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <a id="download" download="poster.png">
                        <Download onClick={download}>Download</Download>
                    </a>
                </div>
            ) : null}
            {}
        </App>
    );
}

const App = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const Poster = styled.div`
    width: 640px;
    height: 830px;
    position: relative;
    margin: 0 auto;
`;
const Background = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
`;
const PhotoContainer = styled.div`
    display: flex;
    align-items: center;
    width: 67%;
`;
const StudentDetails = styled.div`
    
    position: absolute;
    bottom: 289px;
    left: 56px;
    width: 40%
    
`;
const StudentName = styled.p`
    font-size: 14px;
    color: #1b474e;
    text-transform: capitalize;
    text-overflow: ellipsis;
    font-family: poppinsbold;

    
`;
const StudentId = styled.div`
    width: 32%;
    position: absolute;
    bottom: 115px;
    left: 175px;
    
   
`;

const StudentClass = styled.p`
    font-size: 16px;
    color: #455757;
    text-transform: uppercase;
    font-family: product_sansregular;
`;


const StudentDate = styled.div`
    width: 24%;
    position: absolute;
    bottom: 115px;
    left: 442px;
`;
const StudentSchool = styled.p`
font-size: 16px;
color: #455757;
font-family: product_sansregular;
 text-transform: capitalize;
    
`;

const Bottom = styled.div`
    display: flex;
    margin: 20px auto;
    border: 1px solid #b5e2f0;
    background-color: #f5fbfd;
    border-radius: 20px;
    padding: 30px;
    flex-direction: column;
    width: 60%;
`;
const DetailsHead = styled.span`
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin: 10px 0 20px;
`;
const Form = styled.div`
    text-transform: capitalize;
    font-weight: 500;
    margin: 20px 0;
`;
const StudentInput = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const InputName = styled.p``;
const InputField = styled.input`
    padding: 15px;
    width: 64%;
    color: rgb(0, 0, 0);
    font-size: 17px;
    padding-left: 15px;
    caret-color: rgb(92, 198, 106);
    background-color: #fff;
    border: 1px solid #b5e2f0;
    border-radius: 5px;
`;
const Generate = styled.button`
    font-size: 18px;
    font-weight: 500;
    margin: 10px auto 10px;
    display: block;
    padding: 15px 60px;
    background: #9fdbed;
    border: 1px solid #fff;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background: #b5e2f0;
        border: 1px solid #b5e2f0;
        transition-duration: 0.1s;
    }
`;
const Download = styled.button`
    padding: 20px 100px;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    font-family: "product_sansbold";
    background: #d1e8e0;
    border: 1px solid #d1e8e0;
    margin-top: 30px;
`;
const Upload = styled.input``;
const RenderPoster = styled.div`
    canvas {
        border: 2px solid #e8e8e8;
    }
`;
const ImageDiv = styled.div`
    position: absolute;
    top: 143px;
    left: 221px;
    width: 11.5%;
    height: 11.5%;
    
`;
const Image = styled.img`
    width: 100%;
    height: 100%;
    display: block;
`;

export default EcoPartner;
