import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import background from "../../assets/images/login/login-back.svg";
import { accountsConfig } from "../../axiosConfig";
import { Context } from "../../Store";
import loader from "../../assets/lotties/modal/buttonloader.json";
import Lottie from "react-lottie";
// import "../../assets/css/style.css";

export default function Login() {
    const [hide, setHide] = useState(true);
    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [isButtonLoading, setButtonLoading] = useState(false);
    const history = useHistory();
    const { state, dispatch } = useContext(Context);
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: loader,
        rendererSettings: {},
    };

    const handlePasswordShow = () => {
        setHide(!hide);
    };

    useEffect(() => {
        setTimeout(() => {
            setError(false);
            setErrorMsg("");
        }, 5000);
    }, [errorMsg]);

    const onSubmit = () => {
        if (username && password) {
            setLoading(true);
            accountsConfig
                .post("/api/v1/users/login/manager/", {
                    username: username,
                    password: password,
                    service: "learn",
                })
                .then((response) => {
                    let { StatusCode, data } = response.data;
                    if (StatusCode === 6000) {
                        const user_data = {
                            is_verified: true,
                        };
                        dispatch({
                            type: "UPDATE_USER_DATA",
                            user_data: user_data,
                        });
                        setTimeout(() => {
                            history.push("/");
                            setLoading(false);
                        });
                    } else if (StatusCode === 6001) {
                        setError(true);
                        setErrorMsg(data.message);
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                });
        } else if (!username && password) {
            setError(true);
            setErrorMsg("Username Required");
        } else if (!password && username) {
            setError(true);
            setErrorMsg("Password Required");
        } else {
            setErrorMsg("All Fields are Required");
            setError(true);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            onSubmit();
        }
    };

    return (
        <MainContainer>
            <Left>
                <LogoSpan>
                    <Logo
                        src={
                            require("../../assets/images/stey-logo.svg").default
                        }
                        alt="Logo"
                    />
                </LogoSpan>
            </Left>
            <Contains>
                <Credentials>
                    <ShapeSpan>
                        <Shape
                            src={
                                require("../../assets/images/login/round.png")
                                    .default
                            }
                            alt="Round"
                        />
                    </ShapeSpan>
                    <Title>Let's root together and watch other grow</Title>
                    <Description>
                        An inventive collaboration for a smart dawn inclining
                        kids to match their vision.
                    </Description>
                    <UserInput>
                        <Icon
                            src={
                                require("../../assets/images/login/message.svg")
                                    .default
                            }
                            alt="message"
                        />
                        <InputField
                            type="text"
                            placeholder="Your username"
                            onChange={(e) => setUsername(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </UserInput>
                    <PasswdInput>
                        <Icon
                            src={
                                hide
                                    ? require("../../assets/images/login/lock.svg")
                                          .default
                                    : require("../../assets/images/login/unlock.svg")
                                          .default
                            }
                        />
                        <InputField
                            type={hide ? "password" : "text"}
                            placeholder="Your password"
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <EyeSpan>
                            <Eye
                                onClick={handlePasswordShow}
                                style={{ cursor: "pointer" }}
                                src={
                                    hide
                                        ? require("../../assets/images/login/hide.svg")
                                              .default
                                        : require("../../assets/images/login/eye.svg")
                                              .default
                                }
                            />
                        </EyeSpan>
                    </PasswdInput>
                    <LinkSection isError={isError}>
                        {isError && <ErrorSpan>{errorMsg}</ErrorSpan>}
                    </LinkSection>
                    <BottomButton onClick={onSubmit}>
                        {!isButtonLoading ? (
                            "Login"
                        ) : (
                            <Lottie
                                options={defaultOptions}
                                height={45}
                                width={45}
                            />
                        )}
                    </BottomButton>
                </Credentials>
            </Contains>
        </MainContainer>
    );
}

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const Contains = styled.div`
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    padding: 0px 100px;
    @media (max-width: 1500px) {
        width: 30%;
    }
    @media (max-width: 1300px) {
        padding: 0px 90px;
    }
    @media (max-width: 1150px) {
        width: 40%;
    }
    @media (max-width: 840px) {
        padding: 0px 60px;
    }
    @media (max-width: 740px) {
        padding: 0px 44px;
    }
    @media (max-width: 680px) {
        width: 100%;
    }
    @media (max-width: 450px) {
        width: 100%;
        padding: 0px 30px;
    }
`;
const Credentials = styled.div``;
const ShapeSpan = styled.span`
    display: block;
    width: 29px;
    height: 29px;
    @media (max-width: 680px) {
        display: none;
    }
`;
const Shape = styled.img`
    display: block;
    width: 100%;
    @media (max-width: 680px) {
        display: none;
    }
`;
const Title = styled.h2`
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    font-size: 27px;
    line-height: 1.4em;
    margin-top: 20px;
    font-family: "baloo_paaji_2medium";
    @media (max-width: 480px) {
        font-size: 25px;
    }
`;
const Description = styled.p`
    text-align: left;
    letter-spacing: 0px;
    font-size: 20px;
    color: #707070;
    opacity: 1;
    font-family: baloo_paaji_2medium;
    margin: 18px 0px 40px;
    @media (max-width: 680px) {
        margin: 13px 0px 30px;
    }
    @media (max-width: 480px) {
        font-size: 17px;
        margin: 13px 0px 20px;
    }
`;
const UserInput = styled.div`
    position: relative;
    display: flex;
    justify-content: left;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(47, 51, 55);
    border-image: initial;
    border-radius: 10px;
    padding: 12px;
    @media (max-width: 480px) {
        padding: 10px;
    }
`;

const PasswdInput = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(47, 51, 55);
    border-image: initial;
    border-radius: 10px;
    padding: 12px;
    margin-top: 20px;
    @media (max-width: 480px) {
        padding: 10px;
    }
`;
const Icon = styled.img`
    max-width: 23px;
    max-height: 23px;
    display: block;
`;
const InputField = styled.input`
    width: 100%;
    font-size: 18px;
    margin-left: 15px;
    border: 0;
    font-family: baloo_paaji_2medium;
    @media (max-width: 480px) {
        font-size: 16px;
    }
`;
const EyeSpan = styled.span`
    max-width: 25px;
    max-height: 25px;
    display: block;
`;
const Eye = styled.img`
    width: 100%;
    display: block;
`;
const BottomButton = styled(Link)`
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 38px;
    background: #2bc871;
    border-radius: 10px;
    opacity: 1;
    text-decoration: none;
    font-family: "baloo_paaji_2medium";
    letter-spacing: 0px;
    color: #ffffff;
    font-size: 20px;
    @media (max-width: 480px) {
        height: 44px;
        font-size: 15px;
    }
`;
const Left = styled.div`
    width: 60%;

    background: url(${background}) no-repeat;
    background-position: center top;
    background-size: cover;
    height: 100vh;
    @media (max-width: 1500px) {
        width: 55%;
    }
    @media (max-width: 1150px) {
        width: 45%;
    }
    @media (max-width: 1000px) {
        width: 40%;
    }
    @media (max-width: 680px) {
        display: none;
    }
`;
const LogoSpan = styled.div`
    width: 150px;
    position: absolute;
    left: 70px;
    top: 70px;
`;
const Logo = styled.img`
    width: 100%;
    display: block;
`;
const LinkSection = styled.div`
    display: flex;
    justify-content: ${(props) =>
        props.isError ? "space-between" : "flex-end"};
    align-items: center;
    font-family: baloo_paaji_2medium;
`;
const ErrorSpan = styled.span`
    color: red;
    font-size: 15px;
    display: block;
    font-family: baloo_paaji_2regular;
`;
