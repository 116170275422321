import { useState } from "react";
import "../../App.css";
import img from "../../assets/images/join.jpg";
import html2canvas from "html2canvas";
import styled from "styled-components";
import "../../style.css";

function JoinHigher() {
    const [posterGenerated, setPosterGenerated] = useState(false);
    const [Name, setName] = useState("");
    const [sClass, setsClass] = useState("");
    const [sDivision, setsDivision] = useState("");
    const [School, setSchool] = useState("");
    const [dp, setDp] = useState("");
    const [bgpos, setBgpos] = useState("center");
    const [bgpsize, setbgSize] = useState("cover");

    const download = () => {
        const download = document.getElementById("download");
        let image = document
            .querySelector("canvas")
            .toDataURL("image/png")
            .replace("image/pdf", "image/octet-stream");
        download.setAttribute("href", image);
    };
    const generatePoster = () => {
        setPosterGenerated(true);
        window.scrollTo(0, 0);
        document.getElementById("render").innerHTML = "";
        html2canvas(document.querySelector("#poster"), {
            scale: 2,
        }).then((canvas) => {
            document.getElementById("render").appendChild(canvas);
        });
        window.scrollTo(
            0,
            document.body.scrollHeight || document.documentElement.scrollHeight
        );
    };

    return (
        <App>
            <Poster id="poster">
                <Background src={img} alt="bg" />
                <div
                    style={{
                        position: "absolute",
                        top: "283px",
                        left: "308px",
                        width: "460px",
                        height: "460px",
                        background: `url(${dp}) `,
                        backgroundSize: bgpsize,
                        backgroundPosition: bgpos,
                        borderRadius: "50%",
                    }}
                />
                <StudentDetails>
                    <StudentName>{Name}</StudentName>
                    <StudentClass>{sClass}</StudentClass>
                    {/* <StudentDivision>{sDivision}</StudentDivision> */}
                    <StudentSchool>{School}</StudentSchool>
                </StudentDetails>
            </Poster>
            <Bottom>
                <Upload
                    type="file"
                    id="image"
                    onChange={(e) => {
                        let file = e.target.files[0];
                        if (file) {
                            let reader = new FileReader();

                            reader.onload = () => {
                                setDp(reader.result);
                            };

                            reader.readAsDataURL(file);
                        }
                    }}
                />
                <Form>
                    <StudentInput>
                        <InputName>Student Name</InputName>
                        <InputField
                            type="text"
                            placeholder="Name"
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                        <InputName>Student Class</InputName>
                        <InputField
                            type="text"
                            placeholder="Student class"
                            value={sClass}
                            onChange={(e) => setsClass(e.target.value)}
                        />
                    </StudentInput>
                    {/* <StudentInput>
            <InputName>Class Division</InputName>
            <InputField
              type="text"
              placeholder="Division"
              value={sDivision}
              onChange={(e) =>setsDivision(e.target.value)}
            />
            </StudentInput> */}
                    <StudentInput>
                        <InputName>School</InputName>
                        <InputField
                            type="text"
                            placeholder="School"
                            value={School}
                            onChange={(e) => setSchool(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                        <InputName>background position</InputName>

                        <InputField
                            type="text"
                            placeholder="position"
                            value={bgpos}
                            onChange={(e) => setBgpos(e.target.value)}
                        />
                    </StudentInput>
                    <StudentInput>
                        <InputName>background size</InputName>
                        <InputField
                            type="text"
                            placeholder="size"
                            value={bgpsize}
                            onChange={(e) => setbgSize(e.target.value)}
                        />
                    </StudentInput>
                </Form>
                <Generate onClick={generatePoster}>Generate</Generate>
            </Bottom>
            <RenderPoster
                id="render"
                style={{
                    width: "1080px",
                    height: "1080px",
                }}
            ></RenderPoster>
            {posterGenerated ? (
                <div
                    style={{
                        width: "auto",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <a id="download" download="poster.png">
                        <Download onClick={download}>Download</Download>
                    </a>
                </div>
            ) : null}
            {}
        </App>
    );
}

const App = styled.div``;
const Poster = styled.div`
    width: 1080px;
    height: 1080px;
    position: relative;
    margin: 0 auto;
`;
const Background = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
`;

const StudentDetails = styled.div`
    height: 106px;
    min-width: 100%;
    position: absolute;
    bottom: 158px;
    left: 0;
    text-align: center;
    margin: 10px;
    color: #606060;
    font-size: 14px;
    background: #fff;
    font-family: "baloo_paaji_2semibold";
    text-align: center;
`;
const StudentName = styled.p`
    font-size: 28px;
    margin: 0 auto;
    color: #19484c;
    text-transform: uppercase;
    color: #507239;
    font-weight: 700;
    font-family: "baloo_paaji_2semibold";
    text-overflow: ellipsis;
`;
const StudentClass = styled.p`
    font-size: 15px;
    margin-top: -15px;
    display: block;
    color: #507239;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "baloo_paaji_2semibold";
`;
// const StudentDivision = styled.p`
// display: block;
// font-size: 15px;
// color: #507239;
// margin-top: -15px;
// font-weight: 700;
// text-transform: uppercase;
// font-family: "baloo_paaji_2semibold";
// `;
const StudentSchool = styled.p`
    font-size: 24px;
    color: #18484c;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: -28px;
`;

const Bottom = styled.div`
    display: flex;
    margin: 0px auto;
    flex-direction: column;
    width: 60%;
`;
const Form = styled.div`
    text-transform: capitalize;
    font-weight: 500;
    margin: 20px 0;
`;
const StudentInput = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: inherit;
`;
const InputName = styled.p``;
const InputField = styled.input`
    margin-left: 30px;
    width: 60%;
    color: rgb(0, 0, 0);
    font-size: 17px;
    padding-left: 15px;
    caret-color: rgb(92, 198, 106);
    border: 1px solid #d8c4c4;
    border-radius: 4px;
`;
const Generate = styled.button`
    width: 100px;
    height: 100px;
    margin: 0px auto;
    display: block;
    background: #d1e8e0;
`;
const Download = styled.button`
    font-size: x-large;
    padding: revert;
    margin: 15px;
`;
const Upload = styled.input`
    margin: 0px auto;
    padding-top: 21px;
`;
const RenderPoster = styled.div``;

export default JoinHigher;
