import axios from "axios";

// ### (LIVE SERVER CONFIGS) ### //
// export const chatBaseUrl = "wss://communication.talrop.com/";
// export const learnChannelUrl = "wss://learn.talrop.com/";
export const accountsConfig = axios.create({
	baseURL: "https://accounts.steyp.com",
});
// export const learnConfig = axios.create({
//     baseURL: "https://learn.talrop.com/api/v1",
// });
// export const notificationsConfig = axios.create({
//     baseURL: "https://notifications.talrop.com/api/v1",
// });
// export const coinsConfig = axios.create({
//     baseURL: "https://coins.talrop.com/api/v1",
// });
// export const tceConfig = axios.create({
//     baseURL: "https://tce.talrop.com/api/v1/",
// });
// export const communicationConfig = axios.create({
//     baseURL: "https://communication.talrop.com/api/v1/",
// });

// ### (DEVELOPMENT SERVER CONFIGS) ### //
// export const chatBaseUrl = "wss://developers-communication.talrop.com/";
// export const learnChannelUrl = "wss://learn.talrop.com/";
// export const accountsConfig = axios.create({
//     baseURL: "https://developers-accounts.talrop.com",
// });
// export const learnConfig = axios.create({
//     baseURL: "https://developers-learn.talrop.com/api/v1",
// });
// export const notificationsConfig = axios.create({
//     baseURL: "https://developers-notifications.talrop.com/api/v1",
// });
// export const coinsConfig = axios.create({
//     baseURL: "https://developers-coins.talrop.com/api/v1",
// });
// export const webConfig = axios.create({
//     baseURL: "https://developers-api-tce.talrop.com/api/v1/web",
// });
// export const tceConfig = axios.create({
//     baseURL: "https://developers-api-tce.talrop.com/api/v1",
// });
// export const communicationConfig = axios.create({
//     baseURL: "https://developers-communication.talrop.com/api/v1/",
// });

// ### (LOCAL IP CONFIGS) ### //
// export const chatBaseUrl = "ws://192.168.0.121:8006/";
// export const learnChannelUrl = "ws://192.168.0.121:8001/";
// export const learnUrl = "http://192.168.0.121:8001";
// export const mediaUrl = "http://d3mbaugvr53zg5.cloudfront.net";
// export const accountsConfig = axios.create({
//     baseURL: "http://192.168.0.121:8000",
// });
// export const learnConfig = axios.create({
//     baseURL: "http://192.168.0.121:8001/api/v1",
// });
// export const notificationsConfig = axios.create({
//     baseURL: "http://192.168.0.121:8003/api/v1",
// });
// export const coinsConfig = axios.create({
//     baseURL: "http://192.168.0.121:8002/api/v1",
// });
// export const tceConfig = axios.create({
//     baseURL: "http://192.168.0.121:8007/api/v1/",
// });
// export const communicationConfig = axios.create({
//     baseURL: "http://192.168.0.121:8006/api/v1/",
// });
